<template>
  <div id="au-list">
    <el-button @click="createFormVisible = true">创建用户</el-button>

    <el-dialog title="请输入账户信息" :visible.sync="createFormVisible" width="30%">
      <el-form :model="createForm" ref="createForm">
        <el-form-item label="用户名">
          <el-input v-model="createForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="createForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="createForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="权限">
          <el-checkbox-group v-model="createForm.permissions">
            <el-checkbox v-for="permission in Object.keys(PERMISSION)" :key="permission" :label="permission">
              {{ PERMISSION[permission] }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="createFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doCreate()
        createFormVisible = false;
        ">提交
        </el-button>
      </div>
    </el-dialog>

    <el-divider></el-divider>

    <el-table :data="page.list" border>
      <el-table-column prop="id" label="ID" width="50">
      </el-table-column>
      <el-table-column prop="userName" label="用户名" width="120">
      </el-table-column>
      <el-table-column prop="nickName" label="昵称" width="120">
      </el-table-column>
      <el-table-column label="是否可用" width="80">
        <template #default="{row}">
          <el-switch v-model="row.enable" active-color="#13ce66" inactive-color="#ff4949" @change="editAU(row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="权限">
        <template v-slot="row">
          <el-checkbox-group v-model="row.row.permissions" @change="editAU(row.row)">
            <el-checkbox v-for="permission in Object.keys(PERMISSION)" :key="permission" :label="permission">{{
              PERMISSION[permission]
            }}
            </el-checkbox>
          </el-checkbox-group>
        </template>
      </el-table-column>


      <el-table-column label="操作" width="400">
        <template v-slot="row">
          <el-popover placement="left" trigger="click">
            <el-form>
              <el-form-item label="新密码">
                <el-input v-model="newLoginPwd"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="editPwd(row.row.id, newLoginPwd)">提交</el-button>
              </el-form-item>
            </el-form>

            <el-button slot="reference">修改登录密码</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { list, createAU, editAU, editPwd } from './sys'
import { PERMISSION } from '../../util/constants'
import msg from '../../util/msg'

export default {
  name: "AUList",
  data() {
    return {

      PERMISSION,

      form: {
        id: null,
        userName: null,
      },

      createFormVisible: false,
      createForm: {
        userName: null,
        pwd: null,
        permissions: [],
        nickName: null,
      },

      newLoginPwd: null,

      page: {
        list: null,
      },
    };
  },

  methods: {
    // getDesc,
    createAU,
    editAU,
    editPwd,

    loadList() {
      list(this.form).then((data) => {
        this.page = data;
      });
    },

    doCreate() {
      createAU(this.createForm).then((data) => {
        msg.success();
        this.loadList();
      });
    }

  },

  created() {
    this.loadList();
  },

};
</script>