import axios from "axios";
import msg from "../../util/msg.js";



export function list(param) {
  return axios.get("/sys/auList", { params: param });
}

export function createAU(param) {
  return axios.post("/sys/createAU", param);
}

export function editAU(param) {
  axios.post("/sys/editAU", param).then((resp) => {
    msg.success();
  });
}

export function editPwd(id, newLoginPwd) {
  var param = {
    id: id,
    pwd: newLoginPwd,
  };
  editAU(param);
}
